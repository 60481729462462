import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/theme/index.css' // element 自定义样式
// import { openConsole } from "@/assets/js/vconsole-utils";

if(process.env.VUE_APP_NODE_ENV !== 'production') {
  // openConsole()
}

import { requestUrl } from "./http/requestUrl";
import instance from "./http/http";
import ignore_auth_instance from "./http/http_ignore_auth";
import { isUseOpenData } from "@/common/common";
import store from './store';

import QRCode from 'qrcodejs2'; // 链接转二维码

import VueI18n from 'vue-i18n'; //多语言

// 导入多语言的 JSON 文件
import enLocale from './locales/en.json';
import cnLocale from './locales/cn.json';

Vue.use(VueI18n);
// 创建 VueI18n 实例，并设置默认语言和多语言内容
const i18n = new VueI18n({
    locale: process.env.VUE_APP_SOURCE == 'lishi' ? 'en' : 'cn', // 默认语言
    messages: {
        en: enLocale, // 英文
        cn: cnLocale, // 中文
        // 其他语言...
    }
});

import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueClipboard from 'vue-clipboard2'

import compareWidth from '@/utils/directives';

// 公用方法
import tool from './utils/tools';
//把tools的工具挂载到vue原型上
Object.keys(tool).forEach(val=>{
  Vue.prototype[val] = tool[val];
})
Vue.use(CKEditor);
Vue.config.productionTip = false

Vue.prototype.$http = instance;
Vue.prototype.$http_ignore_auth = ignore_auth_instance;
Vue.prototype.requestUrl = requestUrl;

Vue.prototype.QRCode = QRCode;
Vue.prototype.isUseOpenData = isUseOpenData
import { Base64 } from "js-base64";
Vue.use(Base64);
Vue.use(VueClipboard)

Vue.prototype.main_isLishi = process.env.VUE_APP_SOURCE == 'lishi';
if (Vue.prototype.main_isLishi) {
  sessionStorage.setItem("isLishi", 1);
}

// 是否开启对话中大模型回复流式处理
Vue.prototype.askMassageStream = true;

import { modeCountCompute } from "./mixin/modeCountCompute";

Vue.mixin(modeCountCompute);

/* 路由发生变化修改页面title */
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
Date.prototype.Format = function (fmt) { //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

Date.prototype.format_cus = function() {
  let thisYear = new Date().getFullYear();
  if (thisYear != this.getFullYear()) {
      return this.Format("yyyy-MM-dd hh:mm");
  }
  return this.Format("MM/dd hh:mm");
};

Vue.directive('clickoutside', {
  bind(el, binding) {
    function handler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      let isReturn = false
      !!binding.arg && binding.arg.map(item => {
        if (document.querySelector('#' + item).contains(e.target)) {
          isReturn = true
        }
      })
      if (isReturn) {
        return false
      }

      if (binding.expression) {
        binding.value(e);
      }
    }
    el._zClickOutside = handler;
    document.addEventListener('click', handler);
  },
  unbind(el) {
    // 解除事件监听
    document.removeEventListener('click', el._zClickOutside);
    delete el._zClickOutside;
  }
});

Vue.directive('showtip', {
  // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
  bind: () => {
      // bind的时候无法获取到已经带有ajax数据的DOM元素，宽度为0
  },
  // 被绑定元素插入父节点时调用 (仅保证父节点存在，但不一定已被插入文档中)。
  inserted: (el) => {
      compareWidth(el); // 可以获取到ajax数据的DOM元素，即真实的宽度
  },
  // 所在组件的 VNode 更新时调用，但是可能发生在其子 VNode 更新之前。指令的值可能发生了改变，也可能没有。但是你可以通过比较更新前后的值来忽略不必要的模板更新
  update: (el) => {
      compareWidth(el); // 可以获取到ajax数据的DOM元素，即真实的宽度
  },
});



new Vue({
  router,
  store, // 把store对象添加到vue实例上
  i18n,
  render: h => h(App)
}).$mount('#app')
